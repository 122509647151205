import request from "@/utils/request";

// GetToken
export function getToken(data) {
  return request({ url: `/client/get_token/`, method: "post", data });
}

// RefreshToken
export function refreshToken(data) {
  return request({ url: `/client/refresh_token/`, method: "post", data });
}

// GetInfo
export function getInfo(params) {
  return request({ url: `/client/info/`, method: "get", params });
}

// SetPassword
export function setPassword(data) {
  return request({ url: `/client/set_password/`, method: "post", data });
}

export function getLogoConfigs(params) {
  return request({ url: `/logo/get_configs/`, method: "get", params });
}
