export default {
  path: "/user",
  name: "user",
  component: () => import("@/layouts/UserLayout"),
  redirect: "/user/login",
  children: [
    {
      path: "login",
      name: "login",
      component: () => import("@/views/system/login"),
    },
    {
      path: "set_password",
      name: "set_password",
      component: () => import("@/views/system/setPassword"),
    },
  ],
};
