export default {
  path: "/report",
  name: "report",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/report/batch_inventory",
  children: [
    {
      path: "batch_inventory",
      name: "batch_inventory",
      component: () => import("@/views/report/batchInventory"),
    },
    {
      path: "warehouse_inventory",
      name: "warehouse_inventory",
      component: () => import("@/views/report/warehouseInventory"),
    },
    {
      path: "stock_in_report",
      name: "stock_in_report",
      component: () => import("@/views/report/stockIn"),
    },
    {
      path: "stock_out_report",
      name: "stock_out_report",
      component: () => import("@/views/report/stockOut"),
    },
  ],
};
