import VueRouter from "vue-router";
import system from "./system";
import report from "./report";
import order from "./order";

const index = {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/report/warehouse_inventory",
};

const routes = [index, system, report, order];

export default new VueRouter({ mode: "history", routes });
