

export default {
  state: () => ({
    logoItem: {},
  }),
  mutations: {
    setLogoConfig(state, item) {
      state.logoItem = item;
    },
  },
};
