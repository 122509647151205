import Cookies from "js-cookie";
import VueI18n from "vue-i18n";
import Vue from "vue";

import antdZhCN from "ant-design-vue/es/locale-provider/zh_CN";
import antdEnUS from "ant-design-vue/es/locale-provider/en_US";
import zhHans from "@/locales/language/zhHans.json";
import en from "@/locales/language/en.json";
import momentCN from "moment/locale/zh-cn";
import momentEU from "moment/locale/eu";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: Cookies.get("language") || "zh-Hans",
  messages: {
    "zh-Hans": { ...zhHans, antLocale: antdZhCN, momentLocale: momentCN },
    en: { ...en, antLocale: antdEnUS, momentLocale: momentEU },
  },
});

export function T(text) {
  return i18n.t(`${text}`);
}

export default i18n;
