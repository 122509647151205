
export default {
  path: "/order",
  name: "order",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/order/stock_in_order_list",
  children: [
    {
      path: "stock_in_order_list",
      name: "stock_in_order_list",
      component: () => import("@/views/order/stockInOrderList"),
    },
    {
      path: "stock_in_order_detail",
      name: "stock_in_order_detail",
      component: () => import("@/views/order/stockInOrderDetail"),
    },
    {
      path: "stock_in_order_create",
      name: "stock_in_order_create",
      component: () => import("@/views/order/stockInOrderCreate"),
    },
    {
      path: "stock_out_order_list",
      name: "stock_out_order_list",
      component: () => import("@/views/order/stockOutOrderList"),
    },
    {
      path: "stock_out_order_detail",
      name: "stock_out_order_detail",
      component: () => import("@/views/order/stockOutOrderDetail"),
    },
    {
      path: "stock_out_order_create",
      name: "stock_out_order_create",
      component: () => import("@/views/order/stockOutOrderCreate"),
    },
  ],
};
